var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,valIndex){return _c('li',{key:valIndex},[_vm._v(_vm._s(value))])}),0)}),0)]),_c('validation-observer',{ref:"simpleRules"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-code',{attrs:{"title":_vm.$t('Add_Product.Add_Translation')}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',_vm._l((_vm.itemData.translations),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Add_Product.Language'),"label-for":"language","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"language","state":_vm.transSelect[index] === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.localeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.transSelect[index]),callback:function ($$v) {_vm.$set(_vm.transSelect, index, $$v)},expression:"transSelect[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Add_Product.Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"TranslationName","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.translations[index].name),callback:function ($$v) {_vm.$set(_vm.itemData.translations[index], "name", $$v)},expression:"itemData.translations[index].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Add_Product.Short_Description'),"label-for":"short-description"}},[_c('validation-provider',{attrs:{"name":"short-description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"short-description","state":errors.length > 0 ? false : null},model:{value:(
                            _vm.itemData.translations[index].short_description
                          ),callback:function ($$v) {_vm.$set(_vm.itemData.translations[index], "short_description", $$v)},expression:"\n                            itemData.translations[index].short_description\n                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Add_Product.Description'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"translationDescription","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.translations[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.translations[index], "description", $$v)},expression:"itemData.translations[index].description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[(index > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.translationsremoveItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Add_Product.Remove")))])],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)]),_c('b-col',{attrs:{"sm":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","block":""},on:{"click":_vm.translationsrepeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Add_Product.Add_New")))])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Add_Product.Submit"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }